import React from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { slugify } from '../../utils/url';

const Dot = ({ risk }) => {
  switch (risk) {
    case `none`:
      return <div className="bg-[#3ec69b] w-[10px] h-[10px] rounded" />;
    case `medium`:
      return <div className="bg-[#ffca00] w-[10px] h-[10px] rounded" />;
    default:
      return <div className="bg-[#ff6474] w-[10px] h-[10px] rounded" />;
  }
};

const displayTranslatedName = (hit, lang) => {
  switch (lang) {
    case `fr`:
      return hit.name_fr;
    case `en`:
      return hit.name_en;
    default:
      return hit.name_en;
  }
};

const createTranslatedUrl = (name, lang) => {
  switch (lang) {
    case `fr`:
      return `/products/${slugify(name)}`;
    case `en`:
      return `/en/products/${slugify(name)}`;
    default:
      return `/en/products/${slugify(name)}`;
  }
};

const ProductItem = ({ hit, query }) => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  const productName = displayTranslatedName(hit, language);
  const searchPos = productName.toLowerCase().indexOf(query.toLowerCase());

  return (
    <Link
      to={createTranslatedUrl(productName, language)}
      className="flex flex-row items-center hover:bg-grey"
    >
      <p className="py-2 px-4">
        {/* No match between query and hit name : display without bold */}
        {searchPos === -1 && productName}
        {/* MATCH : display with bolded search */}
        {searchPos !== -1 && (
          <>
            {productName.slice(0, searchPos)}
            <span className="font-[700] xl:font-black">
              {productName.substr(searchPos, query.length)}
            </span>
            {productName.slice(searchPos + query.length)}
          </>
        )}
      </p>
      <Dot risk={hit.risk_pregnant} />
    </Link>
  );
};

export default ProductItem;
