import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Header from '@/components/Header';
import SEO from '@/components/SEO';
import Footer from '@/components/Footer';
import Close from '@/assets/icn/close.svg';
import SearchBar from '../SearchBar';

interface PropsLayout {
  title?: string;
  description?: string;
}

const Layout: React.FC<PropsLayout> = ({ children, title, description }) => {
  const { t } = useTranslation();

  const [showSearch, setShowSearch] = useState(false);

  const startSearch = () => setShowSearch(true);
  const closeSearch = () => setShowSearch(false);

  return (
    <div className="text-black bg-white min-h-screen">
      <SEO title={title} description={description} />
      <Header startSearch={startSearch} />
      {showSearch && (
        <div className="fixed inset-0 bg-[#000000dd] flex justify-center items-center z-20">
          <Close
            className="absolute top-8 right-8 cursor-pointer"
            width="40"
            fill="white"
            onClick={closeSearch}
          />
          <SearchBar placeholder={`${t(`try`)} "${t(`strawberry`)}"`} />
        </div>
      )}
      <div className="pt-[72px] xl:pt-[88px]">{children}</div>
      <Footer />
      {/* <Cookies /> */}
    </div>
  );
};

Layout.defaultProps = {
  title: `La Fraise`,
  description: `Trouvez ce que vous pouvez manger enceinte !`,
};

export default Layout;
