import React, { useState, useMemo, useRef } from 'react';

import algoliasearch from 'algoliasearch/lite';
import { createAutocomplete } from '@algolia/autocomplete-core';
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia';

import { useTranslation } from 'react-i18next';

import Search from '@/assets/search.svg';
import Suggestions from './Suggestions';

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_API_KEY,
);

const SearchBar = ({ placeholder, title }) => {
  const [autocompleteState, setAutocompleteState] = useState({});
  const { t } = useTranslation();
  const inputRef = useRef(null);

  const autocomplete = useMemo(
    () =>
      createAutocomplete({
        onStateChange({ state }) {
          // (2) Synchronize the Autocomplete state with the React state.
          setAutocompleteState(state);
        },
        getSources() {
          return [
            // (3) Use an Algolia index source.
            {
              sourceId: `products`,
              getItemInputValue({ item }) {
                return item.query;
              },
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: process.env.GATSBY_ALGOLIA_INDEX,
                      query,
                      params: {
                        hitsPerPage: 4,
                        highlightPreTag: `<mark>`,
                        highlightPostTag: `</mark>`,
                      },
                    },
                  ],
                });
              },
              getItemUrl({ item }) {
                return item.url;
              },
            },
          ];
        },
      }),
    [],
  );

  return (
    <div
      {...autocomplete.getRootProps({})}
      className="xl:text-left text-center relative xl:text-[20px]"
    >
      <p className="text-white text-[18px] md:text-2xl xl:text-4xl font-[800] mb-8 text-shadow">
        {title || t(`searchTitle`)}
      </p>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        onClick={() => inputRef.current.focus()}
        className="flex flex-row rounded-lg xl:w-[800px] xl:h-[70px] h-[40px] shadow overflow-hidden mb-1 focus-within:outline focus-within:outline-2 focus-within:outline-black bg-white"
      >
        <div className="flex flex-col justify-center bg-white pl-4 mr-[-1px] cursor-pointer">
          <Search className="fill-lightGrey w-[20px]" />
        </div>
        <input
          ref={inputRef}
          className="placeholder:text-lightGrey placeholder:font-thin focus:outline-none h-full p-4 w-full text-[14px] md:text-[16px] xl:text-[20px] bg-white"
          {...autocomplete.getInputProps({
            placeholder: `${placeholder}, ${t(`randomly`)}`,
          })}
        />
      </div>

      <div className="absolute top-[105px] xl:top-[148px] w-full">
        <Suggestions
          autocomplete={autocomplete}
          autocompleteState={autocompleteState}
        />
      </div>
    </div>
  );
};

export default SearchBar;
