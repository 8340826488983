const slugify = (str) =>
  str
    .toLowerCase()
    .trim()
    .normalize(`NFD`)
    .replace(/[\u0300-\u036f]/g, ``)
    .replace(/[\s_-]+/g, `-`)
    .replace(/^-+|-+$/g, ``)
    .replace(/[^\w-]+/g, ``);

export { slugify };
