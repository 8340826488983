import React from 'react';
import { Link } from 'gatsby';
import useMobileMenu from 'react-hooks-use-mobile-menu';
import { useTranslation } from 'react-i18next';

import Logo from '@/assets/logo.svg';
import Search from '@/assets/icn/search.svg';
import Close from '@/assets/icn/close.svg';

import { routes } from '@/utils/routes';

import AppLink from '../AppLink';
import { ColoredTag } from '../Tag';

interface HeaderProps {
  startSearch: () => void;
}

const Header: React.FC<HeaderProps> = ({ startSearch }) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const [isOpen, openMenu, closeMenu] = useMobileMenu();
  const translatedRoutes = routes(language);

  const headerLinks = [
    {
      label: t(`categories`),
      to: translatedRoutes.categories,
    },
    {
      label: t(`pregnancyRisks`),
      to: translatedRoutes.risks,
    },
    {
      label: t(`blog`),
      to: `https://blog.lafraise.app`,
    },
  ];

  return (
    <div className="fixed top-0 w-full z-10 bg-white shadow-sm">
      <div className="xl:w-wide flex mx-auto px-6 xl:px-0 py-5 justify-between">
        <div className="w-full xl:w-auto flex justify-between xl:items-center">
          <Link to={translatedRoutes.base}>
            <Logo className="xl:w-[200px] w-[125px]" />
          </Link>
          <Search
            width={30}
            className="ml-4 cursor-pointer"
            onClick={startSearch}
          />
        </div>

        {/* DESKTOP ONLY */}
        <div className="flex-row items-center hidden xl:flex">
          {headerLinks.map((link) => (
            <AppLink
              className="mr-10 underlineStyle font-[600] text-[16px]"
              to={link.to}
              key={link.to}
            >
              {link.label}
            </AppLink>
          ))}
          <AppLink
            className="text-[16px] hover:-translate-y-1 transition-transform"
            to={translatedRoutes.application}
          >
            <ColoredTag color="bg-[#ff6474]">{t(`downloadApp`)}</ColoredTag>
          </AppLink>
        </div>
      </div>

      {/* MOBILE */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div className="fixed xl:hidden bottom-8 right-8" onClick={openMenu}>
        <ColoredTag color="bg-[#ff6474]">MENU</ColoredTag>
      </div>

      <div
        className={`absolute inset-0 w-full h-screen transition-transform bg-white ${
          isOpen ? `translate-x-0` : `translate-x-full`
        }`}
      >
        <div className="absolute right-8 bottom-8 text-right">
          {headerLinks.map((link) => (
            <AppLink
              className="block mb-6 underlineStyle font-[600] text-[24px]"
              to={link.to}
              key={link.to}
            >
              {link.label}
            </AppLink>
          ))}
          <AppLink className="block text-[24px] mb-12" to="/application">
            <ColoredTag color="bg-[#ff6474]">{t(`downloadApp`)}</ColoredTag>
          </AppLink>
          <Close
            fill="black"
            className="inline"
            width="40"
            onClick={closeMenu}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
