import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

interface PropsSEO {
  title?: string;
  description: string;
}

const SEO: React.FC<PropsSEO> = ({ title, description }) => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  const projectTitle = `La fraise`;
  const finalTitle = title ? `${title} | ${projectTitle}` : projectTitle;

  return (
    <Helmet
      htmlAttributes={{
        lang: language,
      }}
    >
      <title>{finalTitle}</title>
      <meta name="description" content={description} />

      <meta name="og:title" content={finalTitle} />
      <meta name="og:description" content={description} />
      <meta name="og:type" content="website" />
      <meta name="og:site_name" content="lafraise.app" />

      <meta name="apple-itunes-app" content="app-id=1492431752" />

      <script
        async
        src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      />
    </Helmet>
  );
};

SEO.defaultProps = {
  title: null,
};

export default SEO;
