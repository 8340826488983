import React from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { routes } from '@/utils/routes';

import Logo from '@/assets/logo-white.svg';

const Footer: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const translatedRoutes = routes(language);

  return (
    <div className="bg-black text-white">
      <div className="lg:w-wide lg:mx-6 xl:mx-auto w-screen px-6 pt-14">
        <div className="flex md:flex-row flex-col">
          <Link
            to={translatedRoutes.base}
            className="md:w-1/5 mb-4 lg:mb-0 h-fit"
          >
            <Logo className="lg:w-[175px] w-[125px]" />
          </Link>

          <div className="flex md:flex-row flex-col md:w-1/2 justify-evenly mb-12">
            <div className="flex flex-col mb-8">
              <Link
                to={translatedRoutes.categories}
                className="inline-block underlineStyle"
              >
                {t(`footerCategories`)}
              </Link>
              <Link
                to={translatedRoutes.risks}
                className="inline-block underlineStyle"
              >
                {t(`footerRisks`)}
              </Link>
              <Link to="/application" className="inline-block underlineStyle">
                {t(`footerDlApp`)}
              </Link>
            </div>

            <div className="flex flex-col">
              <a
                href="https://blog.lafraise.app"
                className="inline-block underlineStyle"
              >
                {t(`footerBlog`)}
              </a>
              <a
                href="mailto:contact@lafraise.app"
                className="inline-block underlineStyle"
              >
                {t(`footerContact`)}
              </a>
              <Link to="/partners" className="inline-block underlineStyle">
                {t(`footerPartnership`)}
              </Link>
            </div>
          </div>
        </div>

        <div className="text-center pb-5 text-sm">
          ©
          <a href="https://charrette.club" className="mx-1">
            <span className="inline-block underlineStyle">charrette.club</span>
          </a>
          - 2022 -{` `}
          <a href="https://www.lafraise.app/files/cgu-la-fraise.pdf">
            <span className="inline-block underlineStyle">Légal</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
