const translatedRoutes = {
  fr: {
    base: `/`,
    categories: `/categories`,
    products: `/products`,
    application: `/application`,
    risks: `/les-risques-lies-a-la-grossesse`,
  },
  en: {
    base: `/en`,
    categories: `/en/categories`,
    products: `/en/products`,
    application: `/en/application`,
    risks: `/en/pregnancy-risks`,
  },
};

const routes = (lang) => {
  switch (lang) {
    case `fr`:
      return translatedRoutes.fr;
    case `en`:
      return translatedRoutes.en;
    default:
      return translatedRoutes.en;
  }
};

export { routes };
