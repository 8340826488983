import React from 'react';
import { Link } from 'gatsby';

const AppLink = ({ to, children, className = `` }) =>
  to.startsWith(`/`) ? (
    <Link to={to} className={className}>
      {children}
    </Link>
  ) : (
    <a href={to} className={className}>
      {children}
    </a>
  );

export default AppLink;
