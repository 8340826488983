import React from 'react';
import ProductItem from './ProductItem';

const Suggestions = ({ autocomplete, autocompleteState }) => (
  <div {...autocomplete.getPanelProps({})}>
    {autocompleteState.isOpen &&
      autocompleteState.collections.map((collection, index) => {
        const { items } = collection;

        return (
          items.length > 0 && (
            <ul
              key={`source-${index}`}
              className="w-full bg-white rounded-lg overflow-hidden outline outline-2 outline-black py-2"
              {...autocomplete.getListProps()}
            >
              {items.map((item) => (
                <ProductItem
                  key={item.objectID}
                  hit={item}
                  query={autocompleteState.query}
                />
              ))}
            </ul>
          )
        );
      })}
  </div>
);

export default Suggestions;
