import React from 'react';
import { useTranslation } from 'react-i18next';

export const ColoredTag = ({ color, children }) => (
  <div className={`px-5 py-3 ${color} rounded-full font-[800] inline-block`}>
    {children}
  </div>
);

const Tag = ({ risk }) => {
  const { t } = useTranslation();

  switch (risk) {
    case `none`:
      return <ColoredTag color="bg-[#3ec69b]">{t(`okTag`)}</ColoredTag>;
    case `medium`:
      return <ColoredTag color="bg-[#ffca00]">{t(`okButTag`)}</ColoredTag>;
    default:
      return (
        <ColoredTag color="bg-[#ff6474]">{t(`notRecommandedTag`)}</ColoredTag>
      );
  }
};

export default Tag;
